@media screen and (min-width:1000px) {
        /* HTML */
        html {
            font-size: 110%;
        }
        /* BODY */
        body {
            background-image: url("data:image/svg+xml,%3Csvg width='1em' height='0.5em' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231670b8' fill-opacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
        /* MAIN */
        .main {
            margin-top: 9.25em;
            margin-left: auto;
            margin-right: auto;
            padding-left: 1em;
            padding-right: 1em;
            width: 100%;
        }
        /* HEADER */
        .header {
            min-height: 8.25em;
        }
        .header .branding {
            padding: 3.375em 1em 0 1em;
            min-height: 8.25em;
          }
        .header .branding img {
            width: 8.33333em;
            height: 2em;
        }
        .header .navigation {
            margin: 1.75em 1.5em 1em 1.5em;
            padding: 0;
        }
    
        /* TOP */
        .top {
            display: flex;
        }
        /* NAVIGATION */
        .nav-toggler {
            display: none;
        }
        .navigation {
            display: block !important;
        }
        /* MENU */

          
        /* MENU LANG */
        .menu-lang {
            position: absolute;
            top: 0.5em;
        }
        /* MENU */
        .header .menu {
            position: absolute;
            bottom: 0;
            margin-bottom: 0;
        }
        .menu-item {
            display: inline-block;
        }
        .menu-item a {
            display: block;
          }
        .menu-item a {
            display: inline-block;
        }
        /* MENU 2 */
        .menu-2 {
            text-align: right;
        }
        .menu-2-item {
            display: inline-block;
        }
        .menu-2-item a {
            display: inline-block;
        }
        /* MENU CHANNELS */
        .menu-channels {
            position: absolute;
            right: 0;
            bottom: 0.75em;
        }

        /* LEGIBLE */
        .legible {
            margin-left: 2em;
            margin-right: 2em;
        }
        
        @supports (display: grid) {
        /* GRID TYPE 2 */
        .grid-container-type-2 {
            max-width: 72em;
            margin-left: 0.5em;
            margin-right: 0.5em;
            padding-bottom: 0.5em;
  
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-auto-rows: auto;
            grid-column-gap: 0.5em;
            grid-row-gap: 1.25em;
            grid-auto-flow: dense;
          }
          .grid-container-type-2 .grid-item-1,
          .grid-container-type-2 .grid-item-2{
            max-width: initial;
            margin-left: initial;
            margin-right: initial;
          }
        }
        /* TIME TABLE & PRICING TABLE */
        .time-table,
        .pricing-table {
            padding-left: 2.5em;
            padding-right: 2.5em;
        }
}